<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :title="(isNew) ? title : item.user.name" back="Access.Members">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid || model.used" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew && !isSelf" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised">

		<app-content-summary v-if="!isNew">

			<app-content-summary-item label="Name" :grow="true" :value="item.user.name" />
			<app-content-summary-item label="E-mail" :value="item.user.email" />

		</app-content-summary>

		<app-content-box v-if="isNew">

			<app-input-account label="E-mail" type="team" :text="emailMessages" v-model="model.email" v-on:exists="onExistsChange" :validation="$v.model.email" />
			<app-input-text :disabled="$v.model.email.$invalid || model.exists" label="Firstname" placeholder="Enter firstname" v-model="model.firstname" :validation="$v.model.firstname" />
			<app-input-text :disabled="$v.model.email.$invalid || model.exists" label="Lastname" placeholder="Enter lastname" v-model="model.lastname" :validation="$v.model.lastname" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { email, requiredIf } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			emailMessages: {
				info: 'If the prospective team member already has a Cardboard Events account then you should use that account\'s e-mail address to link with their profile.',
				added: 'This e-mail address is associated with a Cardboard Events account that already has access.',
				exists: 'This e-mail address is already associated with an existing Cardboard Events account. They will be granted access.',
				register: 'A Cardboard Events login will be generated for this e-mail address and will be granted access. The login credentials will automatically be e-mailed to the user.'
			},
			model: {
				id: false,
				exists: false,
				used: false,
				email: '',
				firstname: '',
				lastname: ''
			}
		}

	},

	validations: {
		model: {
			email: {
				required: requiredIf('isNew'),
				email
			}
		}
	},

	computed: {

		isSelf: function() {

			return this.item.id === this.$id

		}

	},

	methods: {

		onExistsChange: function(e) {

			this.model.exists = e.exists
			this.model.used = e.used
			this.model.firstname = e.firstname || this.model.firstname
			this.model.lastname = e.lastname || this.model.lastname

		}

	}

}

</script>

<style scoped>

</style>